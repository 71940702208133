import React, { Component } from 'react';

class Feature2 extends Component {
    constructor(props){
        super(props);
        this.state = {
           features: [
                {
                    id: 1,
                    icon: 'fa fa-church',
                    title: 'Pastors',
                    text: 'Gain new perspectives on spiritual leadership and how to guide your congregation with compassion and wisdom'
                },
                {
                    id: 2,
                    icon: 'fa fa-book',
                    title: 'Theological Students',
                    text: 'Enhance your understanding of theological concepts and practical applications of spiritual teachings'
                },
                {
                    id: 3,
                    icon: 'fa fa-cross',
                    title: 'Every Christian',
                    text: `Discover practical ways to embody Christ's teachings in your daily life and deepen your relationship with God`
                },
                {
                    id: 4,
                    icon: 'fa fa-search',
                    title: 'Spiritual Seekers',
                    text: 'Explore the profound symbolism of the river in Ezekiel and its relevance to modern spiritual life'
                },
                {
                    id: 5,
                    icon: 'fa fa-users',
                    title: 'Community Leaders',
                    text: 'Learn to lead with love, mercy, and compassion, fostering a more harmonious and supportive community'
                },
                {
                    id: 6,
                    icon: 'fa fa-heart',
                    title: 'Self Growth',
                    text: 'Find inspiration and guidance for personal growth and spiritual development through the teachings of Christ'
                }
            ]
        }
    }

    render() {
        return (
            <section className="feature-2 section-padding ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-heading center-heading text-center mb-60">
                                <h3 className="heading-title">Who This Book Is For</h3>
                                <p>This book is ideal for anyone seeking deeper spiritual insights and practical guidance for living a Christ-like life.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        {
                            this.state.features.map((data,index) => (
                                <div className="col-lg-4 col-md-6">
                                    <div className="feature-style-2 mb-4 mb-lg-0" key={data.id}>
                                        <i className={data.icon}></i>
                                        <div className="feature-text">
                                            <h4>{data.title}</h4>
                                            <p>{data.text}</p>
                                        </div>
                                    </div>
                                </div>
                            
                            ))
                        }
                        
                    </div>
                </div>
            </section> 
        );
    }
}

export default Feature2;
