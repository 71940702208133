import React, { Component } from 'react';

class FeatureLeft extends Component {
    constructor(props){
        super(props);
        this.state = {
            collLeft: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'How to Live Beyond Labels',
                    text: 'Learn to move beyond superficial identities and embrace genuine love and compassion in everyday interactions'
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: `How to Embrace God's Flow`,
                    text: 'Discover the importance of surrendering to the Spirit of God and allowing it to guide your life'
                },
                {
                    id: 3,
                    icon: 'fa fa-check',
                    title: 'The Spirit of The Law',
                    text: 'Understand how to embody love, mercy, and compassion, going beyond merely following rules'
                }
            ]
        }
    }

    render() {
        return (
            <div className="topic-items">
            {
                    this.state.collLeft.map((data,i) => (
                        <div className="topic-item" key={i}>
                            <div className="icon-box">
                                <i className={data.icon}></i>
                            </div>
                            <div className="topic-content">
                                <h4>{data.title}</h4>
                                <p>{data.text}</p>
                            </div>
                        </div>
                    
                    ))
            }
            </div>
        );
    }

}

export default FeatureLeft;
