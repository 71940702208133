import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Banner extends Component {
    constructor(props){
        super(props);
        this.state = {
            listItem: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Learn from an Expert in Detail',
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: '16 Chapters of Deep Spiritual Insights',
                },
                {
                    id: 3,
                    icon: 'fa fa-check',
                    title: 'Available in Paperback on Amazon',
                },
                {
                    id: 4,
                    icon: 'fa fa-check',
                    title: 'Embrace Practical Spiritual Guidance',
                },
                {
                    id: 5,
                    icon: 'fa fa-check',
                    title: 'Discover Christ-like Compassion and Love',
                },
            ]
        }
    }
    render() {
        return (
            <section className="banner pb-80">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12 col-lg-6">
                            <div className="banner-content ">
                                <span className="subheading">Available NOW on Amazon!</span>
                                <h2>Transform Your Life with Spiritual Wisdom</h2>
                                <ul>
                                    {
                                    this.state.listItem.map((data,i) => (
                                        <li key={i}><i className={data.icon}></i> {data.title}</li>
                                        ))
                                    }
                                </ul>
                                <Link to="https://bit.ly/flowing_stone" target="_blank" className="btn btn-main">Get Your Copy Today!</Link>  
                                <p>For just $16.99, you can embark on a journey of spiritual growth and transformation. Click the button above to purchase your copy from Amazon and start flowing in the Spirit today!</p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-img">
                                <img src="assets/images/flowing_cover.png" alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div> 
                </div> 
            </section>

        );
    }
}

export default Banner;

