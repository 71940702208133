import React, { Component } from 'react';

class ContainerLeft extends Component {
    constructor(props){
        super(props);
        this.state = {
            collLeft: [
                {
                    id: 1,
                    subtitle: 'Living Beyond Labels',
                    title: 'Moving Beyond Superficial Identities',
                    text: 'Explore the importance of moving beyond superficial labels to embrace genuine love and compassion in our daily interactions.'
                },
                {
                    id: 2,
                    subtitle: `Embracing God's Flow`,
                    title: 'Surrendering to the Spirit of God',
                    text: 'Learn to surrender to the Spirit of God, allowing it to guide your actions and thoughts for deeper spiritual insights.'
                },
                {
                    id: 3,
                    subtitle: 'The Spirit of The Law',
                    title: 'Embodying Love, Mercy, and Compassion',
                    text: 'Understand the significance of embodying the spirit of the law through love, mercy, and compassion, rather than merely following rules.'
                }
            ]
        }
    }
    render() {
        return (
            <div className="chapter-list border-right-0">
                 {
                    this.state.collLeft.map((data,i) => (
                        <div className="chapter-item" key={i}>
                            <h4><span>{data.subtitle} </span> {data.title}</h4>
                            <p>{data.text} </p>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ContainerLeft;
