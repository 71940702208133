const menus = [
    {
        id: 1,
        tomenu: "#chapters",
        namemenu: "topics"
    },
    {
        id: 2,
        tomenu: "#testimonial",
        namemenu: "reviews"
    },
    {
        id: 3,
        tomenu: "#author",
        namemenu: "author"
    },
    {
        id: 4,
        tomenu: "#contact",
        namemenu: "Contact"
    },
];



export default menus ;