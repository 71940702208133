import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Author extends Component {
    constructor(props){
        super(props);
        this.state = {
            listItem: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Published multiple works on spiritual growth',
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'Dedicated to helping others deepen their faith.',
                },
                {
                    id: 3,
                    icon: 'fa fa-check',
                    title: 'Promotes love, mercy, and compassion in daily life.',
                },
            ]
        }
    }
    render() {
        return (
            <section className="author-section section-padding bg-white" id="author">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="section-heading center-heading text-center mb-60">
                                <h3 className="heading-title">About the author</h3>
                                <p>Benjamin J. Stone is a passionate author dedicated to exploring deep spiritual truths and practical wisdom. His book, "Flowing in the Spirit," aims to guide readers toward a deeper relationship with God through embracing the transformative power of the Holy Spirit.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className=" mb-4 mb-lg-0">
                                 <img src="assets/images/bg/author.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 ps-4">
                            <div className="section-heading mb-0">
                                <h3 className="heading-title">Benjamin J. Stone</h3>
                                <p className="lead">This insightful book challenges rigid doctrines and encourages an open, compassionate mindset, drawing inspiration from the early Church's spiritual understanding.</p>
                            </div>

                            <div className="author-desc">
                                <ul className="list-unstyled">
                                    {
                                    this.state.listItem.map((data,i) => (
                                        <li key={data.id}><i className={data.icon}></i> {data.title}</li>
                                        ))
                                    }
                                </ul>
                                <p>Reach out to me for speaking engagements or anything else in the contact form below.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        );
    }
}

export default Author;
