import React, { Component } from 'react';

class FeatureRight extends Component {
    constructor(props){
        super(props);
        this.state = {
            collRight: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'How to Exhibit Compassion In Action',
                    text: 'Find practical ways to turn your compassionate thoughts into tangible actions that impact others positively'
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'How to Get Deeper in The Word',
                    text: 'Explore deeper levels of spiritual growth and seek continuous revelation and guidance from God'
                },
                {
                    id: 3,
                    icon: 'fa fa-check',
                    title: 'Practical Wisdom',
                    text: 'Gain insights into living a Christ-like life focused on love, compassion, and true spiritual wisdom'
                }
            ]
        }
    }

    render() {
        return (
            <div className="topic-items">
                {
                    this.state.collRight.map((data,i)=> (
                        <div className="topic-item" key={i}>
                            <div className="icon-box">
                                <i className={data.icon}></i>
                            </div>
                            <div className="topic-content">
                                <h4>{data.title}</h4>
                                <p>{data.text}</p>
                            </div>
                        </div>
                    
                    ))
            }
            </div>
        );
    }

}

export default FeatureRight;
