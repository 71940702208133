import React, { Component } from 'react';

class ContainerMid extends Component {
    constructor(props){
        super(props);
        this.state = {
            collMid: [
                {
                    id: 1,
                    subtitle: 'Compassion In Action',
                    title: 'Turning Compassion into Tangible Actions',
                    text: 'Find practical ways to translate your thoughts of compassion into tangible actions that positively impact others.'
                },
                {
                    id: 2,
                    subtitle: 'A Deeper Dive',
                    title: 'Exploring Continuous Spiritual Growth',
                    text: 'Delve deeper into spiritual growth and understanding, seeking continuous revelation and guidance from God.'
                },
                {
                    id: 3,
                    subtitle: 'The Way',
                    title: 'Discovering the Path to Salvation',
                    text: 'Discover the path to salvation and the importance of embodying Christ’s teachings in everyday life.'
                }
            ]
        }
    }
    render() {
        return (
            <div className="chapter-list border-right-0">
                 {
                    this.state.collMid.map((data,i) => (
                        <div className="chapter-item" key={i}>
                            <h4><span>{data.subtitle} </span> {data.title}</h4>
                            <p>{data.text} </p>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ContainerMid;
