import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (          
            <section className="footer pt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 me-auto col-sm-6 col-md-12">
                            <div className="widget footer-widget mb-5 mb-lg-0">
                                <h5 className="widget-title">Benjamin J. Stone</h5>
                                <p className="mt-3">Author of "Flowing in the Spirit." Dedicated to guiding readers toward deeper spiritual growth and understanding. Connect for insights and engagements.</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-8 col-sm-6 col-md-12" align="right">
                            <div className="footer-widget mb-5 mb-lg-0">
                                <h5 className="widget-title">Mail</h5>
                                <div className="col-lg-6 col-md-6">
                                    <div className="footer-widget mb-5 mb-lg-0">
                                        <ul className="list-unstyled">
                                            <li>PO Box 154, 00765</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-btm">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="copyright">
                                    <p>@ 2024 Benjamin J Stone</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="fixed-btm-top">
                    <a href="#top-header" className="js-scroll-trigger scroll-to-top"><i className="fa fa-angle-up"></i></a>
                </div>
            </section>


        );
    }
}

export default Footer;
