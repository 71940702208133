import React, { Component } from 'react';
import Slider from "react-slick";

class Review extends Component {
    constructor(props){
        super(props);
        this.state = {
            testimonial: [
                {
                    id: 1,
                    quoteText: 'Transformative Guide',
                    text: `A transformative guide to deepening your spiritual journey and embracing God's love.`,
                    name: '- Sarah Thompson',
                    desigantion: 'Christian Author',
                },
                {
                    id:2,
                    quoteText: 'Profound Insights',
                    text: 'This book offers profound insights that have greatly enriched my personal faith.',
                    name: '- David Martinez',
                    desigantion: 'Pastor, Grace Community Church',
                },
                {
                    id: 3,
                    quoteText: 'Essential Read',
                    text: 'An essential read for anyone seeking practical wisdom and spiritual growth.',
                    name: '- Emily Carter',
                    desigantion: 'Student, Indiana Bible College',
                },
                {
                    id:4,
                    quoteText: 'Inspiring Masterpiece',
                    text: 'Benjamin J. Stone has crafted a masterpiece that inspires and uplifts the soul.',
                    name: '- Michael Lee',
                    desigantion: 'Christian Blogger',
                },
            ]
        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            centerMode: true,
            arrows: false,
            autoplay: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerPadding: '40px',
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
          };
        return (
        <section className="testimonial section-padding bg-grey " id="testimonial">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                        <div className="section-heading center-heading text-center mb-60">
                            <h3 className="heading-title">Reader Reviews</h3>
                            <p>Hear what readers are saying about "Flowing in the Spirit" and its profound impact on their lives.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
            <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="testimonials-slides">
                            <Slider {...settings} >
                            {
                                this.state.testimonial.map((data) => (
                                    <div className="review-item" key={data.id}>
                                        <div className="client-info">
                                            <div className="rating">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                            <h4>{data.quoteText}</h4>
                                            <p>{data.text}</p>
                                        </div>

                                        <div className="client-desc d-flex align-items-center">
                                            <div className="client-img">
                                                <img src={data.img} alt={data.alt} className="img-fluid"/>
                                            </div>
                                            <div className="client-text">
                                                <h5>{data.name}</h5>
                                                <span className="designation">&nbsp;&nbsp;&nbsp;&nbsp;{data.desigantion}</span>
                                            </div>
                                        </div>
                                    </div> 
                                ))
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    </section>
        );
    }
}

export default Review;
