import React, { Component } from 'react';

import {Header, Banner, Chapter, Features, Feature2,  Author, Review,Contact,Footer} from '../layouts/home01/index';

class Home01 extends Component {
    render() {
        return (
            <div className="counter-scroll bg-home1 has-one-page">       
                <Header />       
                        <Banner />
                        <Chapter />
                        <Features />
                        <Feature2 />
                        <Review />
                        <Author /> 
                        <Contact />
                <Footer />
            </div>
        );
    }
}

export default Home01;