import React, { Component } from 'react';

class ContainerRight extends Component {
    constructor(props){
        super(props);
        this.state = {
            collRight: [
                {
                    id: 1,
                    subtitle: 'Reflections',
                    title: 'Engaging with Spiritual Reflections',
                    text: `Engage with questions and reflections designed to help you apply the book's teachings to your life and grow spiritually.`
                },
                {
                    id: 2,
                    subtitle: 'Practical Wisdom',
                    title: 'Living a Christ-like Life',
                    text: 'Gain insights into living a Christ-like life beyond just following rules, focusing on love and compassion.'
                },
                {
                    id: 3,
                    subtitle: 'Spiritual Growth',
                    title: 'Encouraging Deeper Relationships with God',
                    text: 'Encourage continuous spiritual growth and deeper relationships with God and others, inspired by early Church teachings.'
                }
            ]
        }
    }
    render() {
        return (
            <div className="chapter-list border-right-0">
                 {
                    this.state.collRight.map((data,i) => (
                        <div className="chapter-item" key={i}>
                            <h4><span>{data.subtitle} </span> {data.title}</h4>
                            <p>{data.text} </p>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ContainerRight;
